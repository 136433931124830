import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-buttons flex w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["rounded-l-full", {
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.ALL,
      }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.ALL)))
    }, _toDisplayString(_ctx.$t("general.all")), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.CREATED,
      }),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.CREATED)))
    }, _toDisplayString(_ctx.$t("general.created")), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.FAILED,
      }),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.FAILED)))
    }, _toDisplayString(_ctx.$t("general.failed")), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.ACCEPTED,
      }),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.ACCEPTED)))
    }, _toDisplayString(_ctx.$t("general.accepted")), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.IN_TRANSIT,
      }),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.IN_TRANSIT)))
    }, _toDisplayString(_ctx.$t("general.in_transit")), 3),
    _createElementVNode("button", {
      class: _normalizeClass({
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.RETURNED,
      }),
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.RETURNED)))
    }, _toDisplayString(_ctx.$t("general.returned")), 3),
    _createElementVNode("button", {
      class: _normalizeClass(["rounded-r-full", {
        [_ctx.basicButtonClasses]: true,
        [_ctx.selectedButtonClasses]: _ctx.status === _ctx.ParcelsStatus.DELIVERED,
      }]),
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.statusUpdate(_ctx.ParcelsStatus.DELIVERED)))
    }, _toDisplayString(_ctx.$t("general.delivered")), 3)
  ]))
}