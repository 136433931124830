import { FormElementData } from "@/models/FormElementData";
import countriesService from "@/services/countries.service";
import { prepareOptionsId, SelectOption, Values } from "@/common/FormTools";
import suppliersService from "@/services/suppliers.service";
import productsService from "@/services/products.service";
import { Parcel } from "@/models/Parcel";
import { prepareParcelFormData } from "@/common/ParcelFormTools";
import _ from "lodash";

export const prepareReturnParcelShortFormData = async (
  parcel: Parcel
): Promise<FormElementData> => {
  let userCountries = [] as SelectOption[];
  let userSuppliers = [] as SelectOption[];
  let userProducts = [] as SelectOption[];

  return new Promise((resolve) => {
    Promise.all([
      countriesService.getUserCountries().then((response) => {
        userCountries = prepareOptionsId(response.data.countries);
      }),
      suppliersService
        .getUserSuppliers(parcel?.receiver_address?.country?.id)
        .then((response) => {
          userSuppliers = prepareOptionsId(response.data.suppliers);
        }),
      productsService
        .getUserProducts(
          parcel?.receiver_address?.country?.id,
          parcel?.supplier?.id,
          "return"
        )
        .then((response) => {
          userProducts = prepareOptionsId(response.data.products);
        }),
    ])
      .then(() => {
        resolve({
          id: "return-parcel-form",
          type: "form",
          button: "Submit",
          structure: [
            {
              id: "return-parcel-form-row-1",
              type: "row",
              structure: [
                {
                  id: "return-parcel-form-section-1",
                  type: "section",
                  label: "parcels.labels.shipping",
                  width: "2/3",
                  structure: [
                    {
                      id: "country_id",
                      type: "select",
                      label: "parcels.labels.country_id",
                      placeholder: "parcels.labels.country_id",
                      options: () => userCountries,
                      disabled: () => true,
                      required: () => true,
                    },
                    {
                      id: "supplier_id",
                      type: "select",
                      label: "parcels.labels.supplier",
                      placeholder: "parcels.labels.select_supplier",
                      options: () => userSuppliers,
                      disabledEmptyOption: true,
                      disabled: () => true,
                      required: () => true,
                    },
                    {
                      id: "product_id",
                      type: "select",
                      label: "parcels.labels.product",
                      placeholder: "parcels.labels.select_product",
                      options: () => userProducts,
                      disabledEmptyOption: true,
                      required: () => true,
                    },
                  ],
                },
              ],
            },
            {
              id: "return-parcel-form-row-2",
              type: "row",
              structure: [
                {
                  id: "return-parcel-form-section-2",
                  type: "section",
                  label: "parcels.labels.details",
                  width: "2/3",
                  structure: [
                    {
                      id: "shipper_reference",
                      type: "text",
                      label: "parcels.labels.shipper_reference",
                      placeholder: "general.type_here",
                      required: () => true,
                    },
                  ],
                },
              ],
            },
          ],
        });
      })
      .catch(() => {
        resolve({} as FormElementData);
      });
  });
};

export const getReturnParcelSupplierOptions = async (
  values: Values
): Promise<SelectOption[]> => {
  let options = [] as SelectOption[];
  const countryId = _.get(values, "shipper_address.country_id", false);
  if (countryId) {
    await suppliersService
      .getUserSuppliers(countryId.toString())
      .then((response) => {
        options = prepareOptionsId(response.data.suppliers);
      });
  }
  return options;
};

export const getReturnParcelProductOptions = async (
  values: Values
): Promise<SelectOption[]> => {
  let options = [] as SelectOption[];
  const countryId = _.get(values, "shipper_address.country_id", false);
  const supplierId = _.get(values, "supplier_id", false);
  if (countryId && supplierId) {
    await productsService
      .getUserProducts(countryId.toString(), supplierId.toString(), "return")
      .then((response) => {
        options = prepareOptionsId(response.data.products);
      });
  }
  return options;
};

export const prepareReturnParcelFullFormData =
  async (): Promise<FormElementData> => {
    const returnParcelFullFormData = await prepareParcelFormData({});
    _.get(
      returnParcelFullFormData,
      "structure[0].structure[0].structure[0]"
    ).required = () => false;
    _.get(
      returnParcelFullFormData,
      "structure[0].structure[0].structure[1]"
    ).required = () => true;
    _.get(
      returnParcelFullFormData,
      "structure[0].structure[1].structure[0]"
    ).required = () => true;
    _.get(
      returnParcelFullFormData,
      "structure[0].structure[1].structure[1]"
    ).required = () => false;
    _.get(
      returnParcelFullFormData,
      "structure[1].structure[0].structure[0]"
    ).options = getReturnParcelSupplierOptions;
    _.get(
      returnParcelFullFormData,
      "structure[1].structure[0].structure[0]"
    ).affectedBy = ["shipper_address.country_id"];
    _.get(
      returnParcelFullFormData,
      "structure[1].structure[0].structure[1]"
    ).options = getReturnParcelProductOptions;
    _.get(
      returnParcelFullFormData,
      "structure[1].structure[0].structure[1]"
    ).affectedBy = ["shipper_address.country_id", "supplier_id"];
    _.get(
      returnParcelFullFormData,
      "structure[2].structure[0].structure[1]"
    ).required = () => true;
    return returnParcelFullFormData;
  };
