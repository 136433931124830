import { FormElementData } from "@/models/FormElementData";
import {
  SelectOption,
  Values,
  prepareOptionsName,
  prepareOptionsStringId,
} from "@/common/FormTools";
import _ from "lodash";
import productsService from "@/services/products.service";
import countriesService from "@/services/countries.service";
import { ResourcesFilterParams } from "@/models/ResourcesFilterParams";
import suppliersService from "@/services/suppliers.service";

export const prepareFilterParams = (values: Values): ResourcesFilterParams => {
  return _.pickBy({
    country_ids: (_.get(values, "countries", []) as string[]).join(","),
    supplier_ids: (_.get(values, "suppliers", []) as string[]).join(","),
    product_names: (_.get(values, "products", []) as string[]).join(","),
  });
};

export const filtersData = {
  countries: {
    type: "multi-select",
    id: "countries",
    placeholder: "parcels.list.filters.country",
    options: async (values: Values) => {
      let options = [] as SelectOption[];
      const params = prepareFilterParams(values);
      await countriesService.get(params).then((response) => {
        options = prepareOptionsStringId(response.data.countries);
      });
      return options;
    },
    affectedBy: ["suppliers", "products"],
  },
  suppliers: {
    type: "multi-select",
    id: "suppliers",
    placeholder: "parcels.list.filters.supplier",
    options: async (values: Values) => {
      let options = [] as SelectOption[];
      const params = prepareFilterParams(values);
      await suppliersService.get(params).then((response) => {
        options = prepareOptionsStringId(response.data.suppliers);
      });
      return options;
    },
    affectedBy: ["countries", "products"],
  },
  products: {
    type: "multi-select",
    id: "products",
    placeholder: "parcels.list.filters.product",
    options: async (values: Values) => {
      let options = [] as SelectOption[];
      const params = prepareFilterParams(values);
      await productsService.get(params).then((response) => {
        options = prepareOptionsName(response.data.products);
      });
      return options;
    },
    affectedBy: ["countries", "suppliers"],
  },
} as Record<string, FormElementData>;
