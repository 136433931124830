
import { defineComponent } from "vue";
import InfoField from "@/components/ui/InfoField.vue";
import SectionWrapper from "@/components/layout/SectionWrapper.vue";
import parcelsService from "@/services/parcels.service";
import { ParcelResponse } from "@/models/ParcelResponse";
import Spinner from "@/components/ui/Spinner.vue";

export default defineComponent({
  name: "ParcelDetails",
  components: { Spinner, SectionWrapper, InfoField },
  data() {
    return {
      parcel: {} as ParcelResponse,
      isLoading: true,
    };
  },
  props: {
    parcelId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openTrackingUrl: (tracking_url: string) => {
      window.open(tracking_url, "_blank");
    },
  },
  async mounted() {
    this.isLoading = true;
    const response = await parcelsService.details(this.parcelId);

    this.parcel = response.data;
    this.isLoading = false;
  },
});
