/* eslint-disable */
/* istanbul ignore file */
/* tslint:disable */

export enum ParcelsStatus {
  ALL = "all",
  CREATED = "created",
  ACCEPTED = "accepted",
  FAILED = "failed",
  DELIVERED = "delivered",
  IN_TRANSIT = "in_transit",
  RETURNED = "returned"
}
