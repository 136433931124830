
import { Component, defineComponent, PropType, ref } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import RequestProcessing from "@/components/parcels/action-button/RequestProcessing.vue";
import NoParcelsChecked from "@/components/parcels/action-button/NoParcelsChecked.vue";
import ActionError from "@/components/parcels/action-button/ActionError.vue";
import LabelsSuccess from "@/components/parcels/action-button/LabelsSuccess.vue";
import parcelsService from "@/services/parcels.service";
import ManifestsSuccess from "@/components/parcels/action-button/ManifestsSuccess.vue";
import ExportXlsxSuccess from "@/components/parcels/action-button/ExportXlsxSuccess.vue";
import { downloadFile } from "@/common/DownloadFile";
import {
  Values,
} from "@/common/FormTools";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import { values } from "lodash";
import _ from "lodash";

export default defineComponent({
  name: "ActionButton",
  components: {
    Modal,
    DefaultButton,
  },
  props: {
    accountDetails: {
      type: Object as PropType<AccountDetailsResponse>,
      required: true,
    },
    parcelsIds: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    values: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
  setup(props) {
    const isExpanded = ref<boolean>(false);
    const showActionModal = ref(false);
    const actionModalTitle = ref("");
    const actionModalEnableButton = ref(false);
    const actionModalType = ref<string>();
    const expandActionList = () => {
      isExpanded.value = !isExpanded.value;
    };
    const downloadLabels = () => {
      isExpanded.value = false;
      actionModalEnableButton.value = false;
      actionModalTitle.value = "parcels.list.actions.download_labels";
      showActionModal.value = true;
      if (props.parcelsIds.length) {
        actionModalType.value = "processing";
        return parcelsService
          .downloadLabels(props.parcelsIds)
          .then(() => {
            actionModalType.value = "labels_success";
          })
          .catch(() => {
            actionModalType.value = "error";
          })
          .finally(() => {
            actionModalEnableButton.value = true;
          });
      } else {
        actionModalType.value = "no_parcels_checked";
        actionModalEnableButton.value = true;
      }
    };
    const downloadManifest = () => {
      isExpanded.value = false;
      actionModalEnableButton.value = false;
      actionModalTitle.value = "parcels.list.actions.download_manifest";
      showActionModal.value = true;
      if (props.parcelsIds.length) {
        actionModalType.value = "processing";
        return parcelsService
          .downloadManifest(props.parcelsIds)
          .then((response) => {
            actionModalType.value = "manifests_success";
            downloadFile(response.data, "Manifest.xlsx");
          })
          .catch(() => {
            actionModalType.value = "error";
          })
          .finally(() => {
            actionModalEnableButton.value = true;
          });
      } else {
        actionModalType.value = "no_parcels_checked";
        actionModalEnableButton.value = true;
      }
    };
    const exportXlsx = () => {
      isExpanded.value = false;
      actionModalEnableButton.value = false;
      actionModalTitle.value = "parcels.list.actions.export_xlsx";
      showActionModal.value = true;
      actionModalType.value = "processing";
      return parcelsService.exportXlsx(_.omitBy(props.values, _.isEmpty))
        .then((response) => {
            actionModalType.value = "export_xlsx_success";
            let filename = response.headers['content-disposition'];
            if (filename) {
              filename = filename.split('filename=')[1].split(';')[0];
              filename = filename.replace(/['"]/g, ''); 
            }
            else
              filename = 'ParcelExport.xlsx';
            downloadFile(response.data, filename);
          })
          .catch((error) => {
            actionModalType.value = "error";
          })
          .finally(() => {
            actionModalEnableButton.value = true;
          });
    }
    const actionModalContent = (type: string): Component => {
      switch (type) {
        case "labels_success":
          return LabelsSuccess;
        case "manifests_success":
          return ManifestsSuccess;
        case "export_xlsx_success":
          return ExportXlsxSuccess;
        case "no_parcels_checked":
          return NoParcelsChecked;
        case "error":
          return ActionError;
        case "processing":
        default:
          return RequestProcessing;
      }
    };
    return {
      isExpanded,
      showActionModal,
      actionModalTitle,
      actionModalEnableButton,
      actionModalType,
      expandActionList,
      downloadLabels,
      downloadManifest,
      exportXlsx,
      actionModalContent,
    };
  },
});
