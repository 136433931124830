
import { defineComponent, PropType } from "vue";
import moment from "moment";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import ExpandButton from "@/components/ui/ExpandButton.vue";
import ParcelDetails from "@/components/parcels/ParcelDetails.vue";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import { ParcelsListItem } from "@/models/ParcelsListItem";
import { ParcelStatus } from "@/models/ParcelStatus";
import downloadService from "@/services/download.service";
import _ from "lodash";
import { prepareErrors } from "@/common/Tools";

export default defineComponent({
  name: "ParcelListItem",
  components: {
    ParcelDetails,
    ExpandButton,
    Modal,
    DefaultButton,
  },
  emits: ["checkParcel", "editParcel", "createReturnParcel"],
  data() {
    return {
      isExpanded: false,
      showDetails: false,
      moment,
      isDownloading: false,
      downloadService,
      ParcelStatus,
    };
  },
  props: {
    parcel: {
      type: Object as PropType<ParcelsListItem>,
      required: true,
    },
    accountDetails: {
      type: Object as PropType<AccountDetailsResponse>,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      return _.isEmpty(this.parcel.validation_errors);
    },
    pickup_point_available() {
      return !(
        _.isNull(this.parcel?.pickup_point_address) &&
        _.isNull(this.parcel?.pickup_point_id)
      );
    },
    parcelStatus() {
      if(this.parcel.delivered_at)
        return this.$t("general.delivered");
      if(this.parcel.returned_to_customer_at)
        return this.$t("general.returned");
      if(this.parcel.sent_to_transit_at)
        return this.$t("general.in_transit");
      if(this.parcel.accepted_at)
        return this.$t("general.accepted");
      return this.$t("general.created");
    }
  },
  methods: {
    prepareErrors,
    openTrackingUrl: (tracking_url: string) => {
      window.open(tracking_url, "_blank");
    },
    openIncindentUrl: (
      parcel: typeof ParcelDetails, 
      accountDetails: AccountDetailsResponse
    ) => {
      let references: string[] =
        parcel.references !== null
          ? parcel.references.map((el: any) => el["value"])
          : [];
      let tracking_numbers = [
        ...new Set([
          parcel.tracking_number,
          parcel.shipper_reference,
          parcel.id,
          parcel.supplier_system_number,
          parcel.supplier_order_number,
          ...references,
        ]),
      ];
      let subject = `myParcel4You Incident. ${parcel.tracking_number}`;
      let description = [
        `myParcel4You Incident<br><br><br>`,
        `<i>Please describe the case...</i><br><br><br>`,
        `Supplier: <b>${parcel.supplier.full_name}</b><br>`,
        `Tracking numbers: <b>${encodeURI(
          tracking_numbers.join(", ")
        )}</b><br>`,
        `MyParcel4You ID: <b>${parcel.id}</b><br>`,
        `MyParcel4You Customer: <b>${accountDetails.company_name}</b>`,
      ]
        .join(" ")
        .replace(/#/g, "");
      let email = accountDetails.contact_email;
      // tf_due_at YYY-MM-DD
      // tf_organization_id=123456789
      // request_custom_fields_28372121=other_reason
      let url = `https://parcel4you-help.zendesk.com/hc/en-us/requests/new?ticket_form_id=22091317800721&tf_subject=${subject}&tf_anonymous_requester_email=${email}&tf_description=${description}&tf_priority=normal&tf_type=incident`;
      console.log(url);
      window.open(url, "_blank");
    },
    importImage: (supplier: string) => {
      let res = null;
      try {
        res = require(`../../assets/images/${supplier}.png`);
      } catch (e) {
        console.log(`Image not found for ${supplier}`);
        res = require(`../../assets/images/B2cEurope.png`);
      }
      return res;
    },
  },
});
