/* eslint-disable */
/* istanbul ignore file */
/* tslint:disable */

export enum ParcelStatus {
  CREATED = "created",
  ONE_OF_INFORMATION_RECEIVED = "one_of_information_received",
  IN_TRANSIT = "in_transit",
  OUT_OF_DELIVERY = "out_of_delivery",
  CUSTOMS_CLEARANCE_DELAYED = "customs_clearance_delayed",
  ATTEMPTED_DELIVERY = "attempted_delivery",
  READY_FOR_PICKUP = "ready_for_pickup",
  DELIVERED_TO_PICKUP = "delivered_to_pickup",
  DELIVERED = "delivered",
  RETURNED = "returned",
  UNDELIVERABLE_OR_UNKNOWN = "undeliverable_or_unknown",
}