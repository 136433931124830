
import { defineComponent, PropType } from "vue";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";

export default defineComponent({
  name: "LabelsSuccess",
  props: {
    accountDetails: {
      type: Object as PropType<AccountDetailsResponse>,
      required: true,
    },
  },
});
