
import { defineComponent, ref } from "vue";
import { PropType } from "vue";
import { ParcelsStatus } from "@/models/ParcelsStatus";

const basicButtonClasses =
  "w-1/4 py-2 border border-gray-5 font-semibold hover:border-primary-blue hover:text-white hover:bg-primary-blue ";
const selectedButtonClasses = "border-primary-blue text-white bg-primary-blue";

export default defineComponent({
  name: "StatusButtons",
  props: {
    initialStatus: {
      type: String as PropType<ParcelsStatus>,
      default: ParcelsStatus.ALL,
    },
  },
  emits: ["statusUpdate"],
  setup(props, context) {
    const status = ref(props.initialStatus);
    const statusUpdate = (newStatus: ParcelsStatus) => {
      status.value = newStatus;
      context.emit("statusUpdate", status);
    };
    return {
      status,
      statusUpdate,
      ParcelsStatus,
      basicButtonClasses,
      selectedButtonClasses,
    };
  },
});
